import React, { lazy } from "react";
import { useRoutes, Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";

//Project imports
import MinimalLayout from "../pages/Login/MinimalLayout";
import config from '../config';
import Loadable from "../components/Loader/Loadable";

// MAIN PAGES
const MainLayout = Loadable(lazy(() => import("../layout/index")));
const GamesList = Loadable(lazy(() => import("../pages/GameList/GamesList")));
const GameCreation = Loadable(lazy(() => import("../pages/GameCreation/GameCreation")));
const GameInfo = Loadable(lazy(() => import("../pages/GameInfo/GameInfo")));
const GameInfoPre = Loadable(lazy(() => import("../pages/GameInfo/GameInfoPre")));
const GameHistory = Loadable(lazy(() => import("../pages/GameHistory/GameHistory")));
const About = Loadable(lazy(() => import("../pages/About/About")));
const AboutEmbedded = Loadable(lazy(() => import("../pages/About/AboutEmbedded")));
// const GameStory = Loadable(lazy(() => import("../pages/GameStory/GameStory")));
const GameBidList = Loadable(lazy(() => import("../pages/GameBidList/GameBidList")));
const GameBid = Loadable(lazy(() => import("../pages/GameBid/index")));
const GameBidResult = Loadable(lazy(() => import("../pages/GameBidResult/index")));

// AUTH PAGES
const Login = Loadable(lazy(() => import('../pages/Login/index')));
const Register = Loadable(lazy(() => import('../pages/Login/Register')));
const ForgotPassword = Loadable(lazy(() => import('../pages/Login/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('../pages/Login/ResetPassword')));

export default function Routes() {
    let isAuthenticated = useSelector(state => state.Login.isAuthenticated);
    const AuthRoutes = {
        path: '/',
        element: !isAuthenticated ? <MinimalLayout /> : <Navigate to="/jogos"/>,
        children: [
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/signup',
                element: <Register />
            },
            //DISABLED
            // {
            //     path: '/forgotpassword',
            //     element: <ForgotPassword />
            // },
            // {
            //     path: '/password_reset/:confirm/:uidb64/:token',
            //     element: <ResetPassword />
            // },
        ]
    };

    const MainRoutes = {
        path: '/',
        element:isAuthenticated ? <MainLayout /> : <Navigate to="/login"/>,
        children: [
            {
                path: '/',
                element: <GamesList />
            },
            {
                path: '/create',
                element: <GameCreation />
            },
            {
                path: '/jogos',
                element: <GamesList />
            },
            {
                path: '/historico',
                element: <GameHistory />
            },
            {
                path: '/sobre',
                element: <AboutEmbedded />
            },
            {
                path: '/info-jogo-pre/:slug',
                element: <GameInfoPre />
            },
            {
                path: '/info-jogo/:slug',
                element: <GameInfo />
            },            
            {
                path: '/list-bid/:slug',
                element: <GameBidList />
            },            
            {
                path: '/jogo-bid/:slug',
                element: <GameBid />
            },
            {
                path: '/jogo-bid-resultado/:slug',
                element: <GameBidResult />
            },
        ]
    };

    return useRoutes([MainRoutes, AuthRoutes], config.basename);
};