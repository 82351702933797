const INICIAL_STATE = {
    isAuthenticated: localStorage.getItem('access_token_bid') ? true : false,
    passwordReset: false,
    passwordConfirmation: false,
    newRegister: false,
};

export default function Login(state = INICIAL_STATE, action) {
    switch (action.type) {
        case 'SET_IS_LOGGED':
            return { ...state, isAuthenticated: action.isAuthenticated };
        case 'SET_PASSWORD_RESET':
            return { ...state, passwordReset: action.passwordReset };
        case 'PASSWORD_CONFIRMATION':
            return { ...state, passwordConfirmation: action.passwordConfirmation };
        case 'SET_NEW_REGISTER':
            return { ...state, newRegister: action.newRegister };
        default:
            return state;
    }
}
