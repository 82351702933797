import { createStore, combineReducers } from '@reduxjs/toolkit';
import reducers from './reducers';

export default function configureStore() {
    return createStore(
        combineReducers({
            ...reducers
        }),
        {}
    );
}
