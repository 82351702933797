const INICIAL_STATE = {
    activeGameName: '', // for gamename
    dateTarget: localStorage.getItem('status_game')
};

export default function Game(state = INICIAL_STATE, action) {
    switch (action.type) {
        case 'SET_ACTIVE_GAME_NAME':
            return {
                ...state,
                activeGameName: action.activeGameName
            };
        case 'SET_DATE_TARGET':
            return {
                ...state,
                dateTarget: action.dateTarget
            };
        default:
            return state;
    };
};

