import { createRoot } from "react-dom/client";

// third party
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

// project imports
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ConfigureStore from "./store/ConfigStore";

// style + assets
import "./assets/scss/style.scss";

const store = ConfigureStore();

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

serviceWorker.register();
